import { OnChangeEvent } from 'components/dropdown/component'
import { RasaContext } from 'context'
import { addDays } from 'date-fns'
import {
  AggregateIntervalOptions,
  AggregateMetricOptions, AggregateSegmentationStrategyOptions,
  DateRangesAsDropdownOptions,
  dimensionsFiltersDropdownOptions,
  SourceNameOptions,
  SuspectFilterDropdownOptions
} from 'elasticsearch/constants'
import { RasaBrowserComponent } from 'generic/rasaBrowserComponent'
import { isNil } from 'lodash'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { ScheduleFrequency } from 'shared_server_client/constants'
import { formatDateForES } from 'shared_server_client/dates'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { AnalyticsFilterKeys, ContentFilters, SourceFilters } from './constants'

interface ComponentProps {
  [key: string]: any
}

interface ComponentState {
  [key: string]: any
}

const emptyObject: any = {}

export abstract class
RasaAnalyticsComponent<P extends ComponentProps, S = any> extends RasaBrowserComponent<P, S & ComponentState> {
    public static contextType = RasaContext
    protected sharedStore: SharedStore
    constructor(props: P, initialState: any = emptyObject) {
      super(props, {
        ...initialState,
        selectedDateRange: DateRangesAsDropdownOptions[2],
        selectedSuspectClick: SuspectFilterDropdownOptions[0],
        selectedSourceName: SourceNameOptions[0],
        isSuspectClickAllowed: false,
        isFilterLoaded: false,
        selectedSource: SourceFilters[0],
        selectedContentBlock: ContentFilters[0]
      })
    }

    public componentDidMount() {
      this.sharedStore = SharedStore.instance(this.context)
      Promise.all([
        this.sharedStore.getValue(SharedKeys.activeCommunity),
      ]).then(([activeCommunity]) => {
        const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
        if (activeCommunity._communityInfo.data.schedule[0].frequency !== ScheduleFrequency.weekly ||
            activeCommunity._communityInfo.data.schedule[0].days.length < 3) {
          if (isNil(this.getQuery(AnalyticsFilterKeys.SELECTED_DATE_RANGE))) {
            this.setState({
              selectedDateRange: DateRangesAsDropdownOptions[4],
            })
          }
        }
        this.setState({
          isSuspectClickAllowed: avlFeatures.indexOf(BillingPlanDetailCode.SUSPECT_CLICKS) > -1,
          isFilterLoaded: true,
        })
      })
      this.queryParams()
    }

    public queryParams() {
      if(this.getQuery(AnalyticsFilterKeys.SELECTED_DATE_RANGE)){
        const filter = DateRangesAsDropdownOptions.find(x => x.value === this.getQuery(AnalyticsFilterKeys.SELECTED_DATE_RANGE))
        if(filter) {
          this.dateChanged({selected: {key: filter.key, value: filter.value}})
        }
      }
      if(this.getQuery(AnalyticsFilterKeys.SELECTED_INTERVAL)){
        const filter = AggregateIntervalOptions.find(x => x.value === this.getQuery(AnalyticsFilterKeys.SELECTED_INTERVAL))
        if(filter) {
          this.intervalChanged({selected: {key: filter.key, value: filter.value}})
        }
      }
      if(this.getQuery(AnalyticsFilterKeys.SELECTED_DIMENSION)){
        const filter = dimensionsFiltersDropdownOptions.find(x => x.value === this.getQuery(AnalyticsFilterKeys.SELECTED_DIMENSION))
        if(filter) {
          this.dimensionChanged({selected: {key: filter.key, value: filter.value}})
        }
      }
      if(this.getQuery(AnalyticsFilterKeys.SELECTED_METRIC)){
        const filter = AggregateMetricOptions.find(x => x.value === this.getQuery(AnalyticsFilterKeys.SELECTED_METRIC))
        if(filter) {
          this.metricChanged({selected: {key: filter.key, value: filter.value}})
        }
      }
      if(this.getQuery(AnalyticsFilterKeys.SELECTED_SEGMENT)){
        const filter = AggregateSegmentationStrategyOptions.find(x => x.value === this.getQuery(AnalyticsFilterKeys.SELECTED_SEGMENT))
        if(filter) {
          this.segmentChanged({selected: {key: filter.key, value: filter.value}})
        }
      }
      if(this.getQuery(AnalyticsFilterKeys.SELECTED_CONTENT_BLOCK)){
        const filter = ContentFilters.find(x => x.value === this.getQuery(AnalyticsFilterKeys.SELECTED_CONTENT_BLOCK))
        if(filter) {
          this.contentBlockChanged({selected: {key: filter.key, value: filter.value}})
        }
      }
    }

    public dateChanged = (e: OnChangeEvent) => {
      this.setQuery(AnalyticsFilterKeys.SELECTED_DATE_RANGE, e.selected.value).then(() => {
        this.setState({
          selectedDateRange: e.selected,
        })
      })
    }

    public sourceChanged = (e: OnChangeEvent) => {
      this.setQuery(AnalyticsFilterKeys.SELECTED_SOURCE, e.selected.value)
      this.setState({
        selectedSource: e.selected,
      })
    }

  public contentBlockChanged = (e: OnChangeEvent) => {
    this.setQuery(AnalyticsFilterKeys.SELECTED_CONTENT_BLOCK, e.selected.value)
    this.setState({
      selectedContentBlock: e.selected,
    })
  }

    public suspectedClickChanged = (e: OnChangeEvent) => {
      this.setState({
        selectedSuspectClick: e.selected,
      })
    }

    public sourceClickChanged = (e: OnChangeEvent) => {
      this.setState({
        selectedSourceName: e.selected,
      })
    }

    public intervalChanged = (e: OnChangeEvent) => {
      this.setQuery(AnalyticsFilterKeys.SELECTED_INTERVAL, e.selected.value).then(() => {
        this.setState({
          selectedInterval: e.selected,
        })
      })
    }

    public segmentCodeChanged = (code: any) => {
      this.setState({
        selectedSegmentCode: code.key,
      })
    }

    public createCustomDate = (e) => {
      if (e.value.start === null || e.value.end === null) {
        return
      } else {
         if ((e.value.start < this.state.minCustomDateRange) || (e.value.end > (new Date()))) {
          return
         }
         const customRange = `${formatDateForES(e.value.start)}|${formatDateForES(addDays(e.value.end, 1))}`
         this.setState({
          selectedDateRange: {
            ...this.state.selectedDateRange,
            value: customRange,
           },
        })
      }
    }

    public minCustomDateRange = () => {
      this.context.user.init().then(({ person, activeCommunity }) => {
        if (person.billingInfo.usageStats.reportingLookbackMonths &&
          person.billingInfo.usageStats.reportingLookbackMonths !== -1) {
          this.setState ({minCustomDateRange : (new Date(new Date()
            .setMonth(-Math.abs(person.billingInfo.usageStats.reportingLookbackMonths))))})
        }
      })
      return undefined
    }

  public dimensionChanged = (e: OnChangeEvent) => {
    this.setQuery(AnalyticsFilterKeys.SELECTED_DIMENSION, e.selected.value).then(() => {
      this.setState({
        selectedDimension: e.selected,
      })
    })
  }

  public metricChanged = (e: OnChangeEvent) => {
    this.setQuery(AnalyticsFilterKeys.SELECTED_METRIC, e.selected.value).then(() => {
      this.setState({
        selectedMetric: e.selected,
      })
    })
  }

  public segmentChanged = (e: OnChangeEvent) => {
    this.setQuery(AnalyticsFilterKeys.SELECTED_SEGMENT, e.selected.value).then(() => {
      this.setState({
        selectedSegmentStrategyCode: e.selected,
      })
    })
  }
}
